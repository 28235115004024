import LiveEvent from '../../components/live/live';
import Modal from '../../components/modal/modal';
import Draggon from '../../components/draggon/draggon';

// Тут много про Яндекс-капчу, в целом она тут работает следующим образом
// 1. При каждой загрузке страницы / яндекс-капчи / при ajax-обновлении страницы
//    инициализируются все капчи
// 2. При инициализации каждому контейнеру с капчей выдается свой id от яндекса,
//    мы его пишем в data-container-id,
//    и в обработчик успешной проверки (callback) пишем генерацию события успешной проверки
// 3. При отправке формы проверяем наличие свойства data-captcha-shield и если оно есть,
//    то ищем капчу в этой форме и запускаем smartCaptcha для этого элемента,
//    при этом подписываемся на успешную проверку от Яндекса о капче
//    (которую генерируем в пункте 2) -
//    если событие пришло - отправляем форму стандартым методом

// Это позволяет оставить работу с капчей в одном месте,
// а работу с модалкой в другом, обмениваясь данными через события

new LiveEvent('click', '[data-modal]', function openModal(e) {
  e.preventDefault();
  const link = this;
  const modal = new Modal(this, {
    closerText: '',
    closeOnClickBg: false,
    theme: link.dataset.theme,
    onAfterOpen: () => {
      document.dispatchEvent(new Event('modal-opened'));
    },
    onFormSuccess() {
      if (link.dataset.goal) {
        let action = 'click';
        if (link.dataset.goalAction) {
          action = link.dataset.goalAction;
        }
        window.goal(link.dataset.goal, action);
      }
    },
    onSubmit(form) {
      if (window.smartCaptcha && form.dataset.captchaShield) {
        // Если есть капча - подписываемся на событие успешной проверки
        const containerEl = form.querySelector('[data-captcha-container]');
        const { containerId } = containerEl.dataset;
        if (!containerId) {
          throw new Error('Captcha container id not found');
        }
        document.addEventListener(`captchaSuccess-${containerId}`, () => {
          this.onSubmitDefault(form);
        });
        window.smartCaptcha.execute(containerId);
      } else {
        if (!form.querySelector('[data-question-submit]')) {
          this.onSubmitDefault(form);
        }
      }
    },
  });
  return false;
});

function initYandexCaptcha() {
  if (!window.smartCaptcha || !document.querySelector('[data-captcha-key]')) {
    console.log('Yandex captcha not found');
    return;
  }
  const { captchaKey } = document.querySelector('[data-captcha-key]').dataset;
  const containers = document.querySelectorAll('[data-captcha-container]');
  containers.forEach((containerEl) => {
    // Два раза инициализировать не стоит
    if (containerEl.dataset.containerId) {
      return;
    }

    // Инициализируем капчу
    const widgetId = window.smartCaptcha.render(containerEl.id, {
      sitekey: captchaKey,
      invisible: true,
      hideShield: true,
      callback: (token) => {
        // Генерируем событие успешной проверки
        const { containerId } = containerEl.dataset;
        document.dispatchEvent(new CustomEvent(`captchaSuccess-${containerId}`, { detail: token }));

        // Если капча внутри формы, которая не обрабатывается с помощью JS - просто отправляем форму
        if (!(containerEl.closest('.modal__bg') || containerEl.closest('[data-ajax-form]'))) {
          containerEl.closest('form').submit();
        }
      },
    });
    containerEl.dataset.containerId = widgetId;
  });
}

// Инициализация в трех случаях: при загрузке страницы / яндекс-капчи / при ajax-обновлении страницы
document.addEventListener('DOMContentLoaded', initYandexCaptcha);
document.addEventListener('DOMContentMutated', initYandexCaptcha);
window.onLoadCaptchaFunction = () => {
  initYandexCaptcha();
};

const bind = () => {
  const draggonItems = document.querySelectorAll('[data-draggon]');
  draggonItems.forEach((draggonItem, key) => {
    draggonItem.dataset.draggon = `${key}`;
    const draggon = new Draggon(`[data-draggon="${key}"]`);
    draggon.bindEvents();
  });
};

document.addEventListener('DOMContentLoaded', bind);
document.addEventListener('DOMContentMutated', bind);